.enroll-button {
  text-decoration:none;
  font-weight: 700;
  font-size: 16px;
  color: #142A39;
}
.enroll-button button {
  font-weight: 700;
  font-size: 14px;
  font-family: 'Poppins';
  color: #142A39;
  border-color: #142A39;
}
.enroll {
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 800;
}