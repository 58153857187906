@value colors: 'assets/css/colors.module.css';
@value errorRed from colors;
@value breakpoints:  'assets/css/breakpoints.module.css';
@value sm from breakpoints;
@value md from breakpoints;

.titles {
  text-align: center;
}
.subheadline {
  margin-bottom: 80px;
}
.registration-form {
  display: block;
  margin: 0 50px;
}
.form-fields {
  margin-top: 30px;
}
.registration-headline {
  margin-bottom: 12px;
  text-transform: capitalize;
}
.registration-error-display {
  background: #F9E8E8;
  border: 2px solid errorRed;
  border-radius: 8px;
  padding: 16px 16px 16px 68px;
  background-image: url('assets/img/error.svg');
  background-repeat: no-repeat;
  background-position: left 18px center;
  background-size: 35px 30px;
  margin-bottom: 30px;
  margin-top: 10px;
  display: none;
}
.registration-error-display .error-triangle {
  width: 35px;
  height: 30px;
}
.registration-display-errors {
  display: block;
}
.registration-subtitle {
  font-size: 17px;
  font-weight: 400;
  color: #142A39;
}
.all-fields-required {
  color: errorRed;
  font-size: 12px;
  font-weight: 700;
}
.eligibility-intro {
  font-size: 16px;
}
.eligibility-headline {
  margin-top: 22px;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  color: #142A39;
}
.eligibility-label {
  font-family: 'Helvetica Neue';
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #142A39;
}
.form-row {
  height: 100px;
}
.form-consent {
  /* padding: 10px; */
}
.add-dependent-container {
  margin-bottom: 30px;
}
.submit-button-container {
  margin-top: 20px;
}

.backdrop {
  position: absolute;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.7);
  margin: 0 20px;
  top: -20px;
  bottom: -20px;
}
.backdrop-container {
  position: relative;
}
.invite-outline-button {
  text-decoration:none;
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
  color: #142A39;
  border-color: #142A39;
  font-family: 'Poppins';
}
@media only screen and (max-width: md) {
  .backdrop-container {
    margin-top: 40px;
  }
  .eligibility {
    margin: 0 50px;
  }
  .add-dependent-container {
    margin-top: 30px;
  }
}