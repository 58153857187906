.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}

.textFont {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #142A39;
}

.button_alignment {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}